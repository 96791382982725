.cardNoticia{
    width: 19rem;
}

.cardNoticia img{
    max-width: 100%;
    max-height: 100%;
}

.textoNoticia{
    text-align: justify;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    overflow: hidden;
}