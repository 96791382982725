.nosotros{
    margin-top: 4.8rem;
}
iframe{
    width: 97%;
}

@media (max-width: 968px) {
    iframe{
        height: 300px;
    }
}