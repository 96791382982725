.background{
    background: linear-gradient(220.55deg, #4063BC 0%, #6B0013 100%);
    text-decoration: none;
}
.lista ul{
    list-style: none;
}

.lista ul li{
    font-size: 0.8rem;
}